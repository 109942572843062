.outer_text{
    font-family: "Times New Roman", Times, serif
}
.table{
    padding-top: 3%;
}
.nav-link{
    display: block;
    padding: .5rem 1rem;
     color: #2858b7; 
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    
}
.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    width: 20%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.selectOptionColor{
    color: black !important;
}
@media only screen and (max-width: 600px) {
    .nav-tabs .nav-link {
        margin-bottom: -1px;
        background: 0 0;
        border: 1px solid transparent;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fbfbfb;
    background-color: #2e4fac;
    border-color: #dee2e6 #dee2e6 #fff;
}

.sendButtonVoucher{
    /* width: 20%; */
    font-size: large;
    background-color: black;
    color: white;
    border: 1px solid black;
}

.sendButtonVoucher:hover{
    background-color: white;
    border: 1px solid black;
    color: black;
    box-shadow: 2px 2px 5px #b8b8b8d2;
}
