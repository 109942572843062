.form-control1::placeholder { 
    color: black;
}

.main{
    background-color: white;
    padding: 25px;
}

.sendButton{
    width: 20%;
    font-size: large;
    background-color: black;
    color: white;
    border: 1px solid black;
}

.sendButton:hover{
    background-color: white;
    border: 1px solid black;
    color: black;
    box-shadow: 2px 2px 5px #b8b8b8d2;
}

.sendDiv{
    text-align: center;
    padding-bottom : 15px;
}